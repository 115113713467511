var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay,"rounded":"sm"}},[_c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('div',[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('logo',{staticStyle:{"height":"50px","width":"auto","margin-top":"0.15rem"}})],1),_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('h2',{staticClass:"brand-text text-primary",staticStyle:{"margin-top":"7px","margin-left":"0.5rem","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.appName)+" ")])])])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":_vm.$t('Register')}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-2",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_c('h2',[_vm._v(_vm._s(_vm.$t('MessageRegister.Title')))])]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('MessageRegister.Text'))+" ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"input_name",attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Name')},model:{value:(_vm.registerData.name),callback:function ($$v) {_vm.$set(_vm.registerData, "name", $$v)},expression:"registerData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"register-address"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address","name":"register-address","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Address')},model:{value:(_vm.registerData.address),callback:function ($$v) {_vm.$set(_vm.registerData, "address", $$v)},expression:"registerData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Mobile'),"label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Mobile'),"vid":"mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"input_mobile",attrs:{"id":"register-mobile","name":"register-mobile","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Mobile')},model:{value:(_vm.registerData.mobile),callback:function ($$v) {_vm.$set(_vm.registerData, "mobile", $$v)},expression:"registerData.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"input_email",attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"user@email.com"},model:{value:(_vm.registerData.email),callback:function ($$v) {_vm.$set(_vm.registerData, "email", $$v)},expression:"registerData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{ref:"input_password",staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.registerData.password),callback:function ($$v) {_vm.$set(_vm.registerData, "password", $$v)},expression:"registerData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.registerData.status),callback:function ($$v) {_vm.$set(_vm.registerData, "status", $$v)},expression:"registerData.status"}},[_vm._v(" "+_vm._s(_vm.$t('I agree to'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('privacy policy & terms')))])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Sign up'))+" ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Already have an account?')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sign in instead')))])])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }